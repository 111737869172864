import { ElementType } from 'react';

import { AddressBook, Barn, CurrencyDollar, Horse, House, IconProps, UsersThree } from '@phosphor-icons/react';
import { MotionProps } from 'framer-motion';

import { Roles } from '@modules/auth/constants/Roles';

export interface IMenuSubItem {
  id: string;
  name: string; // Key to translate
  path: string;
  permission?: string;
}

export interface IMenuItem extends MotionProps {
  id: string;
  icon: ElementType<IconProps>;
  name: string; // Key to translate
  path: string;
  permission?: string;
  items?: IMenuSubItem[];
}

export const MenuItems: IMenuItem[] = [
  {
    id: 'home',
    icon: House,
    name: 'home',
    path: '/',
    permission: Roles.dashboard.GET_DASHBOARD,
  },
  {
    id: 'balances',
    icon: CurrencyDollar,
    name: 'balance',
    path: '/balances/dashboard',
    permission: Roles.balances.GET_BALANCE,
    items: [
      {
        id: 'balances-dashboard',
        name: 'balance_dashboard',
        path: '/balances/dashboard',
        permission: Roles.balances.GET_BALANCE,
      },
      {
        id: 'balances-wallets',
        name: 'balance_wallets',
        path: '/balances/wallets',
        permission: Roles.balances.GET_BALANCE_WALLETS,
      },
      {
        id: 'balances-categories',
        name: 'balance_categories',
        path: '/balances/categories',
        permission: Roles.balances.GET_BALANCE_CATEGORIES,
      },
      {
        id: 'balances-reports',
        name: 'balance_reports',
        path: '/balances/reports',
        permission: Roles.balances.GET_BALANCE_REPORTS,
      },
    ],
  },
  // {
  //   id: 'affiliations',
  //   icon: NewspaperClipping,
  //   name: 'affiliations',
  //   path: '/affiliations',
  //   permission: Roles.associationsAffiliations.GET_ASSOCIATIONS_AFFILIATION,
  // },
  {
    id: 'companies',
    icon: Barn,
    name: 'companies',
    path: '/companies',
    permission: Roles.associationsCompanies.GET_ASSOCIATIONS_COMPANIES,
  },
  {
    id: 'riders',
    icon: UsersThree,
    name: 'riders',
    path: '/riders',
    permission: Roles.associationsRiders.GET_ASSOCIATIONS_RIDERS,
  },
  {
    id: 'horses',
    icon: Horse,
    name: 'horses',
    path: '/horses',
    permission: Roles.associationsHorses.GET_ASSOCIATIONS_HORSES,
  },
  {
    id: 'contacts',
    icon: AddressBook,
    name: 'contacts',
    path: '/contacts',
    permission: Roles.contacts.GET_CONTACTS,
  },
];
