/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useParams, useSearchParams } from 'react-router-dom';

import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { AssociationRiderByCompanySlug } from '../types/AssociationRidersPublic/public';
import { FindAssociationRidersByCompanySlugRequest } from '../types/AssociationRidersPublic/requests';

type RouteParams = {
  slug: string;
};

export function useFetchAssociationsRidersPublic() {
  const [urlSearchParams] = useSearchParams();

  const { slug = '' } = useParams<keyof RouteParams>();

  const params: FindAssociationRidersByCompanySlugRequest = {
    companyId: urlSearchParams.get('companyId'),
    limit: Number(urlSearchParams.get('limit')) || 20,
    page: Number(urlSearchParams.get('page')) || 1,
    search: urlSearchParams.get('search'),
    slug,
    year: Number(urlSearchParams.get('year')) || new Date().getFullYear(),
  };

  return useQuery<IPaginateDTO<AssociationRiderByCompanySlug>>({
    enabled: Boolean(params.slug),
    queryKey: ['public:association-riders:list', params],
    queryFn: () => CompaniesClient.associationRidersPublic().find(params),
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
}
