import { tv } from 'tailwind-variants';

export const selectOptionTitleVariants = tv({
  base: 'text-sm',
  variants: {
    isSelected: {
      true: 'text-green-500',
      false: 'text-black-400 dark:text-white',
    },
  },
});

export const selectInputChevronVariants = tv({
  base: 'mx-2 transition-all',
  variants: {
    disable: {
      true: 'fill-gray-400 dark:fill-black-400',
      false: 'fill-black-400 dark:fill-gray-400',
    },
    isOpened: {
      true: 'rotate-[270deg]',
      false: 'rotate-90',
    },
  },
});
