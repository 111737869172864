import { ReactElement } from 'react';

import { Page } from '@shared/components/Page';

import { PublicHorseListCompany } from '@modules/horses/components/PublicHorsesList/PublicHorseListCompany';
import { PublicHorseListFilter } from '@modules/horses/components/PublicHorsesList/PublicHorseListFilter';
import { PublicHorseListFooter } from '@modules/horses/components/PublicHorsesList/PublicHorseListFooter';
import { PublicHorseListHeader } from '@modules/horses/components/PublicHorsesList/PublicHorseListHeader';
import { PublicHorseListItems } from '@modules/horses/components/PublicHorsesList/PublicHorseListItems';
import { useFetchAssociationsHorsesPublic } from '@modules/horses/hooks/useFetchAssociationHorsesPublic';

export const PublicHorsesList = (): ReactElement => {
  const { refetch } = useFetchAssociationsHorsesPublic();

  const onRefresh = async (): Promise<void> => {
    await refetch();
  };

  return (
    <Page className="flex h-full flex-col overflow-y-auto scroll-smooth px-4 py-8 lg:p-10" onRefresh={onRefresh}>
      <PublicHorseListHeader />
      <PublicHorseListCompany />
      <PublicHorseListFilter />
      <PublicHorseListItems />
      <PublicHorseListFooter />
    </Page>
  );
};
